import styles from './Home.module.scss';
import Header from '../../components/Header/Header';
import MainBanner, {
  IBannerType,
} from '../../components/MainBanner/MainBanner';
import rightArrow from '../../assets/rightArrow.png';
import processImage01 from '../../assets/processImage01.png';
import processImage02 from '../../assets/processImage02.png';
import processImage03 from '../../assets/processImage03.png';
import review from '../../assets/review.png';
import ondocService from '../../assets/ondocService.png';
import ondocInfoPrev from '../../assets/ondocInfoPrev.png';
import MainReview from '../../components/MainReview/MainReview';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Footer from '../../components/Footer/Footer';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { IProductListType } from '../ProductList/ProductList';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import MainFaqFrame from '../../components/MainFaqFrame/MainFaqFrame';
import mainFaqData from '../../assets/data/mainFaq.json';
import SwiperCore from 'swiper';
import LazyLoad from 'react-lazy-load';
import ADLink from '../../components/ADLink/ADLink';
import Popup from '../../components/Popup/Popup';
import { useCookies } from 'react-cookie';
export interface IAdLinkDataType {
  id: number;
  product_name: string;
  price: number;
  sale_price: number;
}
const mainFaqList = mainFaqData;
function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  //프로세스
  const [processSliderIdx, setProcessSliderIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  //프로세스
  //상품
  const { data: productList } = useQuery<IProductListType[]>(
    ['productList', 'all'],
    async () => {
      let queryString = '';
      let ids = JSON.parse(
        sessionStorage.getItem('product_ids') || JSON.stringify([]),
      );
      if (ids.length) {
        queryString = 'add_products=';
        ids.map((id: number, idx: number) => {
          queryString += `${id}${idx !== ids.length - 1 ? ',' : ''}`;
        });
      }
      return await axiosClient
        .get(`/data/product/list?${queryString}`)
        .then((res) => res.data.data);
    },
    { staleTime: 60000 * 60 * 24 },
  );
  //상품
  //faq
  // const { data: faqListQuery } = useQuery<{
  //   data: IFaqType[];
  //   faq_type: {
  //     [key: number]: string;
  //   };
  // }>('faq', () => axiosClient.get('/data/faq').then((res) => res.data.data), {
  //   staleTime: 60000 * 60 * 24, //1일 캐싱
  // });
  //faq

  //기존 상품 url접속시 대응
  useEffect(() => {
    let pn = new URLSearchParams(location.search).get('pn');
    if (!pn) return;
    if (pn == 'product.view') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=N&code=${new URLSearchParams(
            location.search,
          ).get('pcode')}`,
        )
        .then((res) => navigate(res.data.data.url));
    } else if (pn == 'blind_gate') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=Y&code=${new URLSearchParams(
            location.search,
          ).get('bc')}`,
        )
        .then((res) => navigate(res.data.data.url));
    }
  }, []);
  //기존 상품 url접속시 대응
  const [doctorSliderIdx, setDoctorSliderIdx] = useState(0);
  const [adLinkOpen, setAdLinkOpen] = useState(true);
  const [adLinkData, setAdLinkData] = useState<IAdLinkDataType>();
  // useEffect(() => {
  //   axiosClient.get('/data/product/promotional_product').then((res) => {
  //     if (!Object.keys(res.data.data).length) return;
  //     setAdLinkData(res.data.data);
  //   });
  // }, []);

  //프로세스슬라이더
  const [ondocInfoIndex, setOndocInfoIndex] = useState<number>(1);
  const [slideList, setSlideList] = useState<IBannerType[]>([]);
  useEffect(() => {
    setSlideList(() => {
      let list: IBannerType[] = [
        { image: processImage01 },
        { image: processImage02 },
        { image: processImage03 },
      ];

      return [...list];
    });
  }, []);
  const handleOndocInfoChange = (swiper: SwiperCore) => {
    setOndocInfoIndex(swiper.realIndex);
  };

  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    if (cookies['week-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  return (
    <>
      {adLinkOpen && adLinkData && (
        <ADLink
          productId={adLinkData.id}
          productName={adLinkData.product_name}
          price={adLinkData.price}
          discountedPrice={adLinkData.sale_price}
          closer={setAdLinkOpen}
        />
      )}
      {/* {!hasCookie && <Popup />} */}
      <div className={styles.container}>
        <Header />
        <MainBanner />
        <div className={styles.normalProductWrap}>
          <h3 onClick={() => navigate(`/productList?cuid=488`)}>
            <span>건강식품</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>다이어트에 최적화된 건강한 영양 식품</span>
          <Swiper slidesPerView={2.5} spaceBetween={20}>
            {productList &&
              productList
                ?.filter((product) => product.is_prescription === 0)
                .map((product, idx) => (
                  <SwiperSlide key={idx}>
                    <div
                      className={styles.productSlideWrap}
                      onClick={() =>
                        navigate(`/productDetail/${product.product_id}`)
                      }
                    >
                      <div className={styles.productThumbnailWrap}>
                        <img
                          src={product.list_image + '?w=530'}
                          alt="productThumbnail"
                        />
                      </div>
                      <div className={styles.productInfoWrap}>
                        <div className={styles.productInfoLeft}>
                          <p>{product.title}</p>
                          <div className={styles.productInfoPrice}>
                            <span>
                              {product.discount_price.toLocaleString()}원
                            </span>
                            {product.price !== 0 && (
                              <span>{product.price.toLocaleString()}원</span>
                            )}
                          </div>
                          <div className={styles.productInfoScore}>
                            <img src={review} alt="review" />
                            <span>
                              {product.review_score_avg?.review_total_count.toLocaleString()}
                            </span>
                          </div>
                        </div>
                        <div className={styles.productInfoRight}></div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
        <LazyLoad>
          <MainReview />
        </LazyLoad>
        <div className={styles.faqWrap}>
          <h3 onClick={() => navigate(`/faq`)}>
            <span>자주 받는 문의사항</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>커넥트닥터 FAQ</span>
          <ul>
            {mainFaqList?.map((faq, idx) => (
              <MainFaqFrame faq={faq} key={idx} />
            ))}
          </ul>
        </div>
        <div className={styles.ondocInfo}>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            className={styles.processSlider}
            onSwiper={setSwiper}
            onSlideChange={(swiper) => handleOndocInfoChange(swiper)}
            loop
          >
            <div
              className={styles.handleBtn}
              onClick={() => swiper?.slidePrev()}
            >
              <img src={ondocInfoPrev} alt="" />
            </div>
            <div
              className={`${styles.handleBtn} ${styles.rightHandleBtn}`}
              onClick={() => swiper?.slideNext()}
            >
              <img
                src={ondocInfoPrev}
                alt=""
                style={{ transform: 'rotate(180deg)' }}
              />
            </div>
            <SwiperSlide className={styles.processSlide1}>
              <div className={styles.text}>
                <h3>
                  <span className={styles.themeSpan}>
                    원격 진료를 간편하고 정확하게
                  </span>
                </h3>
                <p>
                  내원없이 언제 어디서나 쉽고 간편하게
                  <br />
                  정확한 진료를 받아볼 수 있습니다.
                </p>
              </div>
              <div className={styles.processImageWrap}>
                <img src={processImage01} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.processSlide2}>
              <div className={styles.text}>
                <h3>
                  <span className={styles.themeSpan}>처방약을 쉽고 빠르게</span>
                </h3>
                <p>
                  의료 서비스 이용에 어려움을 겪는 분들에게
                  <br />
                  처방한 약을 쉽고 빠르게 배송합니다.
                </p>
              </div>
              <div className={styles.processImageWrap}>
                <img src={processImage03} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.processSlide3}>
              <div className={styles.text}>
                <h3>
                  <span className={styles.themeSpan}>
                    정성과 과학으로 만듭니다.
                  </span>
                </h3>
                <p>
                  검증된 약재와 맞춤 처방으로 안전한 효과를
                  <br />
                  추구하며, 식약처 인증된 의약용품만을 사용합니다.
                </p>
              </div>
              <div className={styles.processImageWrap}>
                <img src={processImage02} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
          <ul className={styles.paginationWrap}>
            {slideList.map((banner, idx) => (
              <li
                key={idx}
                onClick={() => swiper?.slideTo(idx + 1, 200)}
                className={idx === ondocInfoIndex ? styles.hit : ''}
              ></li>
            ))}
          </ul>
        </div>
        <div className={styles.ondocService}>
          <span className={styles.bigText}>
            한방의 지혜와 현대 기술이 결합된 <br />
            커넥트닥터의 비대면 진료를 만나보세요!
          </span>
          <span className={styles.smallText}>
            병원에 방문하지 않고도 언제 어디서나 간편한 한방 비대면 진료!
            <br />
            커넥트닥터를 통해 건강한 라이프스타일을 구현하세요.
          </span>
        </div>
        <div className={styles.serviceInfoWrap}>
          커넥트닥터는 결제기능 및 배송운영 서비스로 진료 및 처방에는 관여하지
          않습니다. <br />
          처방 및 진료를 위해 제공하는 정보는 커넥트닥터 법무팀의 법적 자문을
          받은 의료진
          <br /> 고유의 재산으로 제3자의 무단 침해 및 가공 사용의 경우 법적
          책임을 받을 수 있습니다.
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Home;
